import React from "react";

import discoverTitle from "../../../img/discoverTitle.png";
import DiscoverCard from "./DiscoverCard";

import DiscoverBg1 from "../../../img/1.png";
import DiscoverBg2 from "../../../img/2.png";
import DiscoverBg3 from "../../../img/3.png";
import DiscoverBg4 from "../../../img/4.png";

import { Discover1, Discover2, Discover3, Discover4 } from "../../../img/svgs";

const Discover = () => {
  return (
    <section className="mt-40 hidden">
      <div className="container flex flex-col items-center justify-center gap-12 px-4 md:px-8 lg:px-16">
        <div className="flex flex-col items-center justify-center gap-2">
          <img src={discoverTitle} alt="Discover Title" className="w-[150px]" />
          <span className="text-center text-[28px] font-semibold leading-[32px] text-[#100c08] md:text-[35px] md:leading-[40px] lg:text-[45px] lg:leading-[49.50px]">
            Exploring Tour Activities
          </span>
        </div>

        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          <DiscoverCard
            title={"Bungee Jumping"}
            bg={DiscoverBg1}
            icon={<Discover1 />}
          />

          <DiscoverCard
            title={"Rafting"}
            bg={DiscoverBg2}
            icon={<Discover2 />}
          />

          <DiscoverCard
            title={"Paragliding"}
            bg={DiscoverBg3}
            icon={<Discover3 />}
          />

          <DiscoverCard
            title={"Ski touring"}
            bg={DiscoverBg4}
            icon={<Discover4 />}
          />
        </div>
      </div>
    </section>
  );
};

export default Discover;

import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import clientsTop from "../../img/clientsTop.png";
import ClientsCard from "../../components/cards/ClientsCard";
import Upcoming from "../../components/upcoming/Upcoming";

import ziplineImg from "../../img/zipline.jpg";
import cateringImg from "../../img/catering.jpg";
import cyclingImg from "../../img/cycling.jpg";
import potteringImg from "../../img/pottering.jpg";

const Clients = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-col justify-between">
      <Header />
      <main className="mt-20 flex flex-col gap-20">
        <div
          style={{ backgroundImage: `url(${clientsTop})` }}
          className="flex h-[350px] w-full bg-cover bg-center bg-no-repeat"
        ></div>
        <div className="container flex items-start justify-between gap-10">
          <div className="flex w-full max-w-[856px] shrink-0 flex-col gap-10">
            <ClientsCard
              image={ziplineImg}
              title={
                "Adrenaline Rush: Zipline Through Stunning Mountain Landscapes"
              }
              description={
                "Prepare for the ultimate adventure as you zipline through awe-inspiring mountain scenery! Feel the thrill of gliding high above lush forests and dramatic landscapes, with breathtaking panoramic views at every turn. Whether you're an adrenaline junkie or a first-time flyer, our expert guides ensure a safe and unforgettable experience. Soar through the treetops, feel the wind in your face, and capture memories that will last a lifetime.\n" +
                "\n"
              }
            />

            <ClientsCard
              image={cateringImg}
              title={
                "Ocean Thrills: Open Boat Adventure Along Stunning Coastlines"
              }
              description={
                "Prepare for an unforgettable open boat adventure that will awaken your senses and leave you yearning for more! Feel the invigorating sea breeze as you skim across crystal-clear waters, surrounded by breathtaking coastal views. Cruise through serene bays and discover hidden coves, each turn offering a new surprise. Whether you’re seeking excitement or peaceful exploration, this journey promises awe-inspiring moments and memories to last a lifetime.\n" +
                "\n"
              }
            />

            <ClientsCard
              image={cyclingImg}
              title={
                "Pedal Power: Discover Nature’s Beauty on an Epic Bicycle Adventure"
              }
              description={
                "Gear up for an unforgettable journey as you explore the great outdoors on an exhilarating bicycle trip! Feel the wind in your hair and the freedom of the open road as you cycle through diverse landscapes, from vibrant forests to scenic coastal trails. This isn’t just a ride—it’s a full-body adventure that will reawaken your sense of wonder, challenge your limits, and leave you feeling more alive than ever before!"
              }
            />

            <ClientsCard
              image={potteringImg}
              title={
                "Craft, Create, Connect: A Hands-On Pottery Workshop for Creative Spirits"
              }
              description={
                "Immerse yourself in the art of pottery with our interactive workshop, where creativity blends seamlessly with fun! In this lively and inspiring space, a group of talented girls gathers around pottery wheels, each shaping her own unique masterpiece. With clay in their hands and sunlight pouring through the windows, the room buzzes with laughter, creativity, and a shared sense of accomplishment. Join us for a memorable experience that celebrates artistic expression, friendship, and the simple joy of creating something truly beautiful!\n" +
                "\n"
              }
            />
          </div>
          <Upcoming />
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Clients;

import React, { useState } from "react";
import { HeaderLogo, HeaderPhone } from "../../img/svgs";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="fixed left-0 top-0 z-10 w-full bg-emerald-50 py-5">
      <div className="container flex flex-wrap items-center justify-between">
        <Link to={"/"} className="flex-shrink-0">
          <HeaderLogo className="h-8 w-auto sm:h-10" />
        </Link>

        <div className="z-50 block lg:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-500 hover:text-gray-700 focus:text-gray-700 focus:outline-none"
          >
            {isOpen ? (
              <FaTimes className="h-6 w-6" />
            ) : (
              <FaBars className="h-6 w-6" />
            )}
          </button>
        </div>

        <nav
          className={`fixed right-0 top-0 h-full w-64 bg-emerald-50 p-5 transition-transform duration-300 ease-in-out ${
            isOpen ? "translate-x-0 transform" : "translate-x-full transform"
          } lg:static lg:flex lg:w-auto lg:transform-none lg:items-center lg:justify-center`}
        >
          <ul className="flex flex-col lg:flex-row lg:items-center lg:gap-8">
            <li className="flex items-center justify-center">
              <NavLink
                className="border-b-2 border-b-transparent font-medium transition hover:border-b-2 hover:border-b-[#63ab45] hover:text-[#63ab45]"
                to={"/"}
              >
                Home
              </NavLink>
            </li>
            <li className="flex items-center justify-center">
              <NavLink
                className="border-b-2 border-b-transparent font-medium transition hover:border-b-2 hover:border-b-[#63ab45] hover:text-[#63ab45]"
                to={"/about"}
              >
                About
              </NavLink>
            </li>
            <li className="flex items-center justify-center">
              <NavLink
                className="border-b-2 border-b-transparent font-medium transition hover:border-b-2 hover:border-b-[#63ab45] hover:text-[#63ab45]"
                to={"/tours"}
              >
                Tours
              </NavLink>
            </li>
            <li className="flex items-center justify-center">
              <NavLink
                className="border-b-2 border-b-transparent font-medium transition hover:border-b-2 hover:border-b-[#63ab45] hover:text-[#63ab45]"
                to={"/gallery"}
              >
                Gallery
              </NavLink>
            </li>
            <li className="flex items-center justify-center">
              <NavLink
                className="border-b-2 border-b-transparent font-medium transition hover:border-b-2 hover:border-b-[#63ab45] hover:text-[#63ab45]"
                to={"/contact"}
              >
                Contact
              </NavLink>
            </li>
            <li className="flex items-center justify-center">
              <NavLink
                className="border-b-2 border-b-transparent font-medium transition hover:border-b-2 hover:border-b-[#63ab45] hover:text-[#63ab45]"
                to={"/clients"}
              >
                Happy Clients :)
              </NavLink>
            </li>
          </ul>
        </nav>

        <div className="hidden lg:flex lg:items-center lg:gap-4">
          <div className="flex border-r-2 border-r-gray-300 px-2">
            <HeaderPhone />
          </div>
          <div className="flex flex-col items-start justify-center gap-1 px-2">
            <span className="text-sm font-medium leading-[14px] text-[#100c08]">
              To More Inquiry
            </span>
            <span className="text-lg font-semibold leading-tight text-[#63ab45]">
              <a href="tel:+998(88)111-85-32">+998(88)111-85-32</a>
            </span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import aboutTop from "../../img/aboutTop.png";
import {
  AboutCalendar,
  AboutFlex,
  AboutGuide,
  AboutGuided,
  AboutLighting,
  AboutPlane,
  AboutSucces,
  AboutSupport,
  AboutTime,
  AboutTraveler,
} from "../../img/svgs";
import aboutImg from "../../img/aboutBg.jpg";
import Tours from "../../components/home/tours/Tours";

const Clients = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex min-h-screen w-full flex-col justify-between">
      <Header />
      <main className="mt-20 flex flex-col gap-20">
        <div
          style={{ backgroundImage: `url(${aboutTop})` }}
          className="flex h-[350px] w-full bg-cover bg-center bg-no-repeat"
        ></div>

        <section className="container flex flex-col gap-24">
          <div className="flex items-center justify-between gap-6">
            <div className="flex w-full max-w-[635px] flex-1 flex-col items-start">
              <span className="text-[45px] font-semibold leading-[49.50px] text-[#100c08]">
                Making Every Tour an Unforgettable Experience
              </span>
              <span className="mt-8 text-[17px] font-normal leading-loose tracking-wide text-[#787878]">
                We specialize in providing professional guided tours across the
                stunning landscapes and historic cities of Uzbekistan. Whether
                you're looking for a relaxing city tour or an adventurous
                mountain escape, we offer customized tour packages tailored to
                your preferences. Our packages include comfortable stays at 4-5
                star hotels, experienced guides, and reliable transport services
                — all at reasonable prices. Let us help you explore the best of
                Uzbekistan with a personalized and unforgettable travel
                experience.
              </span>

              <div className="mt-8 flex w-full justify-between gap-6">
                <div className="flex flex-col gap-6">
                  <div className="flex items-center justify-start gap-[15px] rounded-[10px] bg-[#63ab45]/10 px-5 py-3">
                    <div className="flex items-center justify-center rounded-[28px] bg-white p-[15px]">
                      <AboutTime />
                    </div>
                    <div className="flex items-center justify-center py-px">
                      <span className="text-[17px] font-medium capitalize leading-snug tracking-tight text-[#100c08]">
                        Time and Stress Savings
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-start gap-[15px] rounded-[10px] bg-[#63ab45]/10 px-5 py-3">
                    <div className="flex items-center justify-center rounded-[28px] bg-white p-[15px]">
                      <AboutTime />
                    </div>
                    <div className="flex items-center justify-center py-px">
                      <span className="text-[17px] font-medium capitalize leading-snug tracking-tight text-[#100c08]">
                        Time and Stress Savings
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  <div className="flex items-center justify-start gap-[15px] rounded-[10px] bg-[#63ab45]/10 px-5 py-3">
                    <div className="flex items-center justify-center rounded-[28px] bg-white p-[15px]">
                      <AboutTime />
                    </div>
                    <div className="flex items-center justify-center py-px">
                      <span className="text-[17px] font-medium capitalize leading-snug tracking-tight text-[#100c08]">
                        Time and Stress Savings
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-start gap-[15px] rounded-[10px] bg-[#63ab45]/10 px-5 py-3">
                    <div className="flex items-center justify-center rounded-[28px] bg-white p-[15px]">
                      <AboutTime />
                    </div>
                    <div className="flex items-center justify-center py-px">
                      <span className="text-[17px] font-medium capitalize leading-snug tracking-tight text-[#100c08]">
                        Time and Stress Savings
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <button className="mt-12 flex items-center justify-center rounded-[35px] border-b-2 border-[#4a8631] bg-[#63ab45] pb-4 pl-[34px] pr-[33.62px] pt-[13px]">
                <span className="text-base font-semibold capitalize leading-none tracking-wide text-white">
                  Find Out More
                </span>
              </button>
            </div>

            <div className="flex flex-1 items-center justify-center">
              <img
                className="w-full max-w-[550px] rounded-[10px]"
                src={aboutImg}
                alt="About"
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-evenly border-y border-y-gray-300 py-8">
            <div className="flex flex-1 items-center justify-center gap-5">
              <AboutTraveler />
              <div className="flex flex-col gap-0.5">
                <div className="flex items-center gap-0.5">
                  <span className="mt2 text-[27px] font-semibold leading-[27px] text-[#100c08]">
                    1
                  </span>
                  <span className="text-[27px] font-bold leading-[27px] text-[#100c08]">
                    k+
                  </span>
                </div>
                <span className="text-base font-normal leading-snug text-[#787878]">
                  Happy Traveler
                </span>
              </div>
            </div>

            <div className="flex flex-1 items-center justify-center gap-5 border-x border-x-gray-300">
              <AboutSucces />
              <div className="flex flex-col gap-0.5">
                <div className="flex items-center gap-0.5">
                  <span className="mt2 text-[27px] font-semibold leading-[27px] text-[#100c08]">
                    500
                  </span>
                  <span className="text-[27px] font-bold leading-[27px] text-[#100c08]">
                    +
                  </span>
                </div>
                <span className="text-base font-normal leading-snug text-[#787878]">
                  Tours Success
                </span>
              </div>
            </div>

            <div className="flex flex-1 items-center justify-center gap-5">
              <AboutGuide />
              <div className="flex flex-col gap-0.5">
                <div className="flex items-center gap-0.5">
                  <span className="mt2 text-[27px] font-semibold leading-[27px] text-[#100c08]">
                    10
                  </span>
                  <span className="text-[27px] font-bold leading-[27px] text-[#100c08]">
                    +
                  </span>
                </div>
                <span className="text-base font-normal leading-snug text-[#787878]">
                  Travel Guide
                </span>
              </div>
            </div>
          </div>
        </section>

        <section className="container mt-10 flex flex-col gap-14">
          <span className="flex w-full justify-center text-center text-5xl font-semibold leading-[52.80px] text-[#100c08]">
            Why GuideTour Uzbekistan Is The Best?
          </span>
          <div className="flex flex-wrap justify-between gap-6">
            <div className="flex w-full max-w-[415px] gap-5 rounded-[10px] border border-[#eeeeee] px-3 py-5">
              <div className="flex items-center justify-center rounded-full bg-[#ddeed6] p-4">
                <AboutPlane />
              </div>
              <div className="flex flex-col gap-3">
                <span className="text-xl font-medium capitalize leading-7 tracking-wide text-[#100c08]">
                  Worldwide Coverage
                </span>
                <span className="text-[15px] font-normal leading-[27px] tracking-wide text-[#787878]">
                  Curabitur convallis enim atnora ullamcorper sagittis.
                </span>
              </div>
            </div>

            <div className="flex w-full max-w-[415px] gap-5 rounded-[10px] border border-[#eeeeee] px-3 py-5">
              <div className="flex items-center justify-center rounded-full bg-[#feeed5] p-4">
                <AboutLighting />
              </div>
              <div className="flex flex-col gap-3">
                <span className="text-xl font-medium capitalize leading-7 tracking-wide text-[#100c08]">
                  Competitive Pricing
                </span>
                <span className="text-[15px] font-normal leading-[27px] tracking-wide text-[#787878]">
                  Burabitur convallis enim atnora. Morbi nug scelerisque for
                  thana.
                </span>
              </div>
            </div>

            <div className="flex w-full max-w-[415px] gap-5 rounded-[10px] border border-[#eeeeee] px-3 py-5">
              <div className="flex items-center justify-center rounded-full bg-[#f5f5d3] p-4">
                <AboutCalendar />
              </div>
              <div className="flex flex-col gap-3">
                <span className="text-xl font-medium capitalize leading-7 tracking-wide text-[#100c08]">
                  Fast Booking
                </span>
                <span className="text-[15px] font-normal leading-[27px] tracking-wide text-[#787878]">
                  Fermentum eitorx quis maximum Etiam urnan posuere convallis.
                </span>
              </div>
            </div>

            <div className="flex w-full max-w-[415px] gap-5 rounded-[10px] border border-[#eeeeee] px-3 py-5">
              <div className="flex items-center justify-center rounded-full bg-[#ddeed6] p-4">
                <AboutGuided />
              </div>
              <div className="flex flex-col gap-3">
                <span className="text-xl font-medium capitalize leading-7 tracking-wide text-[#100c08]">
                  Guided Tours
                </span>
                <span className="text-[15px] font-normal leading-[27px] tracking-wide text-[#787878]">
                  Fermentum eitorx quis maximum Etiam urnan posuere convallis.
                </span>
              </div>
            </div>

            <div className="flex w-full max-w-[415px] gap-5 rounded-[10px] border border-[#eeeeee] px-3 py-5">
              <div className="flex items-center justify-center rounded-full bg-[#f5f5d3] p-4">
                <AboutSupport />
              </div>
              <div className="flex flex-col gap-3">
                <span className="text-xl font-medium capitalize leading-7 tracking-wide text-[#100c08]">
                  Best Support 24/7
                </span>
                <span className="text-[15px] font-normal leading-[27px] tracking-wide text-[#787878]">
                  Fermentum eitorx quis maximum Etiam urnan posuere convallis.
                </span>
              </div>
            </div>

            <div className="flex w-full max-w-[415px] gap-5 rounded-[10px] border border-[#eeeeee] px-3 py-5">
              <div className="flex items-center justify-center rounded-full bg-[#feeed5] p-4">
                <AboutFlex />
              </div>
              <div className="flex flex-col gap-3">
                <span className="text-xl font-medium capitalize leading-7 tracking-wide text-[#100c08]">
                  Ultimate Flexibility
                </span>
                <span className="text-[15px] font-normal leading-[27px] tracking-wide text-[#787878]">
                  Fermentum eitorx quis maximum Etiam urnan posuere convallis.
                </span>
              </div>
            </div>
          </div>
        </section>

        <Tours />
      </main>
      <Footer />
    </div>
  );
};

export default Clients;
